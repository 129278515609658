import React, { useState, useEffect } from 'react';
import { useTable, useSortBy, useFilters, usePagination } from 'react-table'
import BTable from 'react-bootstrap/Table';
import {CSVLink} from 'react-csv';

import { DateRangePicker, Stack } from 'rsuite';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';
import addDays from 'date-fns/addDays';
import startOfMonth from 'date-fns/startOfMonth';
import endOfMonth from 'date-fns/endOfMonth';
import addMonths from 'date-fns/addMonths';
import moment from 'moment';

// Utilities
import 
{ getSessionDateRange
, setSessionDateRange
, getSessionPageSize
, setSessionPageSize
} from '../../utils/session';

// Components
import Alert from '../layout/Alert';

//Styles
import styles from './ReportTable2.module.css';
import 'rsuite/dist/rsuite.min.css';

const predefinedRanges = 
  [ { label: 'Today'      , value: [new Date(), new Date()]                           , placement: 'left' }
  , { label: 'Yesterday'  , value: [addDays(new Date(), -1), addDays(new Date(), -1)] , placement: 'left' }
  , { label: 'This week'  , value: [startOfWeek(new Date()), endOfWeek(new Date())]   , placement: 'left' }
  , { label: 'Last week'  ,
      value: value => {
        const [start = new Date()] = value || [];
        return [
          addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
          addDays(endOfWeek(start, { weekStartsOn: 0 }), -7)
        ];
      },
      placement: 'left'
    }
  , { label: 'This month' , value: [startOfMonth(new Date()), new Date()]                                           , placement: 'left' }
  , { label: 'Last month' , value: [startOfMonth(addMonths(new Date(), -1)), endOfMonth(addMonths(new Date(), -1))] , placement: 'left' }
  ];

const ReportTable2 = ({header, columns, reportName, refreshDataFunc, columnBuilder}) => {
  const [ csvTable, setCsvTable ] = useState('');
  const [ reportCols, setReportCols ] = useState(columns);
  const [ data, setData ] = useState([]);
  const [ alerts, setAlerts ] = useState([]);

  useEffect(() => {
    fetchDataForDates(getSessionDateRange());
    // eslint-disable-next-line
  }, [refreshDataFunc]);

  const fetchDataForDates = async (selectedDateRange) => {
    if (!selectedDateRange) {
      setData([]);
      return;
    }
    setSessionDateRange(selectedDateRange);
    const startDate = moment(selectedDateRange[0]).format('YYYY-MM-DD');
    const endDate   = moment(selectedDateRange[1]).add(1, 'd').format('YYYY-MM-DD');
    const result = await refreshDataFunc(startDate, endDate);

    if (result?.status!==200) {
      setAlerts([...alerts, {heading: 'Hang On!', msg:result.message, type:'danger'}]);
      setTimeout(() => setAlerts([]), 6000);
      return;
    }

    if (result.data?.colHeaders) setReportCols(columnBuilder(result.data?.colHeaders));

    if (result.data?.reportData) {
      setData(result.data.reportData); // dynamic column reporting
    } else {
      setData(result.data); // fixed column reporting
    }
  };

  const formatCSV = (reportName) => {
    // Maps the report data from the server to the CSV output
    let dataMap = [];
    switch (reportName) {
      case 'agentEnrollments':
        dataMap = [];
        for (const i in reportCols[0].columns) {
          let field = reportCols[0].columns[i].fieldName;
          if (field.startsWith('enrollData.')) {
            dataMap.push({ [field.substr(11)]:field });
          } else {
            dataMap.push({ [field]:field });
          }
        }
        break;
      case 'revokedEnrollments':
        dataMap = [];
        for (const i in reportCols[0].columns) {
          let field = reportCols[0].columns[i].fieldName;
          if (field.startsWith('enrollData.')) {
            dataMap.push({ [field.substr(11)]:field });
          } else {
            dataMap.push({ [field]:field });
          }
        }
        break;
      default:
    }

    // build the header row
    let rows = [];
    rows.push(dataMap.map( (col) => { return Object.keys(col) } ));

    // add the rows
    if (data?.length) {
      for (const record of data) {
        let row = [];
        for (const col of dataMap) {
          const field = Object.values(col)[0];
          if (field.startsWith('enrollData.')) {
            const enrollDataField = field.substr(11);
            if (record?.enrollData?.[enrollDataField]) {
              row.push(`${record.enrollData[enrollDataField]}`);
            } else {
              row.push('');
            }
          } else {
            row.push(`${record[field]}`);
          }
        }
        rows.push(row);
      }
    }
    
    setCsvTable(rows);
  };

  // Define a default UI for filtering
  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    return (
      <input className={styles.formControl}
        value={filterValue || ''}
        onChange={e => {
          setFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
        }}
        placeholder={`search...`}
      />
    )
  };

  // Our table component
  function Table({ columns: reportCols, data}) {
    data = data || [];
    const filterTypes = React.useMemo(
      () => ({
        // Or, override the default text filter to use
        // "startWith"
        text: (rows, id, filterValue) => {
          return rows.filter(row => {
            const rowValue = row.values[id]
            return rowValue !== undefined
              ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
              : true
          })
        },
      }),
      []
    );

    const defaultColumn = React.useMemo(
      () => ({
        // Let's set up our default Filter UI
        Filter: DefaultColumnFilter,
      }),
      []
    );

    const {
      getTableProps,
      getTableBodyProps,
      headerGroups,
      page,
      prepareRow,
      canPreviousPage,
      canNextPage,
      pageOptions,
      pageCount,
      gotoPage,
      nextPage,
      previousPage,
      setPageSize,
      state: { pageIndex, pageSize },
    } = useTable(
      { columns: reportCols
      , data
      , initialState: { pageIndex: 0, pageSize: getSessionPageSize()}
      , defaultColumn // Be sure to pass the defaultColumn option
      , filterTypes
      },
      useFilters, // useFilters!
      useSortBy,
      usePagination,
    );

    const generateSortingIndicator = column => {
      return column.isSorted ? (column.isSortedDesc ? ' ▼' : ' ▲') : ''
    };

    return (
      <>
        <BTable striped bordered hover size="sm" {...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())} style={{verticalAlign:'top'}}>
                    {column.render('Header')}
                    {generateSortingIndicator(column)}
                    {/* Render the columns filter UI */}
                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row) => {
              prepareRow(row)
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    return <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                  })}
                </tr>
              )
            })}
          </tbody>
        </BTable>

        <br />

        <div className="row pagination">
          <div className="col-lg-4 paginationFooterLeft">
            <button  onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              &#171;
            </button>
            <button onClick={() => previousPage()} disabled={!canPreviousPage}>
              &#8249;
            </button>
            <button onClick={() => nextPage()} disabled={!canNextPage}>
              &#8250;
            </button>
            <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
              &#187;
            </button>
          </div>
          <div className="col-lg-4 paginationFooterMid">
            <span>
              Page{' '}
              <strong>
                {pageIndex + 1} of {pageOptions.length}
              </strong>{' '}
            </span>
          </div>
          <div className="col-lg-4 paginationFooterRight">
            <span>
              Go to page:{' '}
              <input className="paginationGoToPage"
                type="number"
                defaultValue={pageIndex + 1}
                onChange={e => {
                  let page = e.target.value ? Number(e.target.value) - 1 : 0
                  if (page >= pageOptions.length){
                    e.target.value = pageOptions.length;
                    page = pageOptions.length;
                  }
                  if (page < 0){
                    e.target.value = 1;
                  }
                  gotoPage(page)
                }}
              />
            </span>
            <select className="form-select-page"
                    value={pageSize}
                    onChange={e => {
                      setPageSize(Number(e.target.value));
                      setSessionPageSize(Number(e.target.value));
                    }}
                  >
                    {[25, 50, 100].map(pageSize => (
                      <option key={pageSize} value={pageSize}>
                        Show {pageSize}
                      </option>
                    ))}
            </select>
          </div>
        </div>

      </>
    )
  };

  return (
    <>
      <div className={styles.reportHeader}>
        <Alert alerts={alerts} />
        <div className={styles.reportTitle}>
          {header}
        </div>

        <div className={styles.reportDateRange}>
          <Stack direction="column" spacing={8} alignItems="flex-start">
            <DateRangePicker
              ranges={predefinedRanges}
              showOneCalendar
              placeholder="Select Date Range"
              defaultCalendarValue={getSessionDateRange()}
              defaultValue={getSessionDateRange()}
              style={{ width: 300 }}
              onShortcutClick = {(shortcut , _event) => { fetchDataForDates(shortcut?.value) }}
              onOk            = {(shortcut , _event) => { fetchDataForDates(shortcut) }}
              onClean         = {(_shortcut, _event) => { fetchDataForDates() }}
            />
          </Stack>
        </div>

        <div className={styles.reportButtons}>
          <CSVLink
            className="btn btn-primary"
            style={{ width: "140px" }}
            data={csvTable} 
            asyncOnClick={true}
            onClick={()=>formatCSV(reportName)}
            filename={`${reportName}.csv`}
          >Export to CSV
          </CSVLink>
        </div>
      </div>

      <div className={styles.tableWrapper}>
        <Table className={styles.report} columns={reportCols} data={data} />
      </div>
    </>
  )
};

export { ReportTable2 };