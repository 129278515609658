import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

// Routing components
import PrivateRoute from './components/routing/PrivateRoute';

// Context
import { RelyingPartyProvider } from './context/RelyingPartyContext';

// Layout components
import Navbar from './components/layout/Navbar';

// Auth components
import Login from './components/auth/Login';

// Manage-users components
import UserList from './components/manage-users/UserList';
import UserRegistration from './components/manage-users/UserRegistration';
import EditUser from './components/manage-users/EditUser';

//Reports
import AgentEnrollments from './components/reports/AgentEnrollments';
import AgentEnrollAndFirstAuthReport from './components/reports/AgentEnrollAndFirstAuthReport';
import AuthenticationReport from './components/reports/AuthenticationReport';
import RevokedEnrollments from './components/reports/RevokedEnrollments';
import EnrollmentActivity from './components/reports/EnrollmentActivity';
import PendingEnrollments from './components/reports/PendingEnrollments';

// User components
import Profile from './components/user/Profile';
import ChangeEmailSuccess from './components/user/ChangeEmailSuccess';
import ChangePasswordSuccess from './components/user/ChangePasswordSuccess';
import ForgotPassword from './components/user/ForgotPassword';
import ResetPassword from './components/user/ResetPassword';

// For AAD authentications
import { MsalProvider } from '@azure/msal-react';

const App = ({ instance }) => {
  return (
    <MsalProvider instance={instance}>
      <Router>
        <>
          <section style={{width:'95%'}} className='container'>
            <RelyingPartyProvider>
              <Navbar />
              <Switch>
                <Route        exact path='/'                                    component={Login} />
                <Route        exact path='/forgot-password'                     component={ForgotPassword} />
                <Route              path='/reset-password/:userId/:token'       component={ResetPassword} />
                <PrivateRoute exact path='/user-list'                           component={UserList} />
                <PrivateRoute exact path='/agent-enrollments-report'            component={AgentEnrollments} />
                <PrivateRoute exact path='/agent-enroll-and-first-auth-report'  component={AgentEnrollAndFirstAuthReport} />
                <PrivateRoute exact path='/authentication-report'               component={AuthenticationReport} />
                <PrivateRoute exact path='/revoked-enrollments-report'          component={RevokedEnrollments} />
                <PrivateRoute exact path='/enrollment-activity-report'          component={EnrollmentActivity} />
                <PrivateRoute exact path='/pending-enrollments-report'          component={PendingEnrollments} />
                <PrivateRoute exact path='/user-registration'                   component={UserRegistration} />
                <PrivateRoute exact path='/edit-user'                           component={EditUser} />
                <PrivateRoute exact path='/profile'                             component={Profile} />
                <Route        exact path='/change-email-success'                component={ChangeEmailSuccess} />
                <Route        exact path='/change-password-success'             component={ChangePasswordSuccess} />
              </Switch>
            </RelyingPartyProvider>
          </section>
        </>
      </Router>
    </MsalProvider>
  );
};

export default App;