import React, { useEffect, useState } from 'react';
import { Link, useLocation} from 'react-router-dom';
import { jwtDecode } from 'jwt-decode';
import { gSubdomain } from '../../utils/config';
import { getAccessToken } from '../../utils/session';

const TabMenu = () => {
  const [isReportViewer, setIsReportViewer] = useState(false);
  const location = useLocation();
  const pathName = location.pathname;

  useEffect(() => {
    async function checkUserRoles() {
      const {token} = await getAccessToken();
      if (!token) return;
      const decodedToken = jwtDecode(token);
      setIsReportViewer(decodedToken?.roles?.includes('view-reports'));
    };

    checkUserRoles();  
  }, []);

  return (
    <>
      <ul className='nav nav-tabs' role='tablist'>
        <li className={'nav-item' + ( pathName.startsWith('/user-list') ? ' active' : '')}>
          <Link to={'/user-list/#'+gSubdomain} className={'nav-link' + ( pathName.startsWith('/user-list') ? ' active' : '')}>
            Agents
          </Link>
        </li>
        { isReportViewer ?
          <li className={'nav-item' + ( pathName.startsWith('/agent-enrollments-report') ? ' active' : '')}>
            <Link to={'/agent-enrollments-report/#'+gSubdomain} className={'nav-link' + ( pathName.startsWith('/agent-enrollments-report') ? ' active' : '')}>
              Agent Enrollments
            </Link>
          </li> : ''
        }
        { isReportViewer ?
          <li className={'nav-item' + ( pathName.startsWith('/agent-enroll-and-first-auth-report') ? ' active' : '')}>
            <Link to={'/agent-enroll-and-first-auth-report/#'+gSubdomain} className={'nav-link' + ( pathName.startsWith('/agent-enroll-and-first-auth-report') ? ' active' : '')}>
              Agent Enroll &amp; 1st Auth
            </Link>
          </li> : ''
        }
        { isReportViewer ?
          <li className={'nav-item' + ( pathName.startsWith('/authentication-report') ? ' active' : '')}>
            <Link to={'/authentication-report/#'+gSubdomain} className={'nav-link' + ( pathName.startsWith('/authentication-report') ? ' active' : '')}>
              Authentications
            </Link>
          </li> : ''
        }
        { isReportViewer ?
          <li className={'nav-item' + ( pathName.startsWith('/revoked-enrollments-report') ? ' active' : '')}>
            <Link to={'/revoked-enrollments-report/#'+gSubdomain} className={'nav-link' + ( pathName.startsWith('/revoked-enrollments-report') ? ' active' : '')}>
              Revoked Enrollments
            </Link>
          </li> : ''
        }
        { isReportViewer ?
          <li className={'nav-item' + ( pathName.startsWith('/enrollment-activity-report') ? ' active' : '')}>
            <Link to={'/enrollment-activity-report/#'+gSubdomain} className={'nav-link' + ( pathName.startsWith('/enrollment-activity-report') ? ' active' : '')}>
              Enrollment Activity
            </Link>
          </li> : ''
        }
        { isReportViewer ?
          <li className={'nav-item' + ( pathName.startsWith('/pending-enrollments-report') ? ' active' : '')}>
            <Link to={'/pending-enrollments-report/#'+gSubdomain} className={'nav-link' + ( pathName.startsWith('/pending-enrollments-report') ? ' active' : '')}>
              Pending Enrollments
            </Link>
          </li> : ''
        }
      </ul>
    </>
  );
};

export default TabMenu;