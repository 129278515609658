import React from 'react';
import moment from 'moment';
import { ReportTable2 } from './ReportTable2';
import { getAgentEnrollments } from '../../utils/services';

const _phoneNumberSearchFilter = (rows, columnId, filterValue) => {
  return rows.filter(row => {
    // Remove phone number formatting characters
    const cleanFilterValue = filterValue.replace(/\D/g, '');
    const rowValue = row.values[columnId].replace(/\D/g, '');
    return String(rowValue).includes(cleanFilterValue);
  });
};

const _buildAccessor = (field) => {
  if (field.endsWith('Dtm')) {
    return (d) => {
      if (!d[field]) return '';
      return moment.utc(d[field]).local().format('MMM DD, YYYY LTS (ddd)');
    };
  }
  if (field === 'mobileNumber') {
    return (d) => {
      const cleaned = ('' + d[field].slice(-10)).replace(/\D/g, '');
      const match = /^(\d{3})(\d{3})(\d{4})$/.exec(cleaned);
      return (match) ? '(' + match[1] + ') ' + match[2] + '-' + match[3] : d[field];
    };
  }
  return field; // return it as-is
};

const _buildDynamicColumns = (colHeaders) => {
  const columns = [];
  for (const i in colHeaders) {
    const field  = colHeaders[i][0];
    const header = colHeaders[i][1];
    const newColumn =
      { Header          : header
      , accessor        : _buildAccessor(field)
      , disableFilters  : field.endsWith('Dtm') // date fields are not searchable
      , fieldName       : field
      };
    if (field==='mobileNumber') {
      newColumn['filter'] = _phoneNumberSearchFilter;
    }
    columns.push(newColumn);
  }
  return [{ Header: 'Agent Enrollments', columns} ];
};

const AgentEnrollments = () => {
  const columns = [{ Header: 'foo bar', columns: []}];
  return (
    <>
      <div className='row'>
        <div className='col-md-12'>
          <div className='round-border card'>
            <ReportTable2
              header='Date range' /* label before date selector */
              columns={columns} 
              reportName='agentEnrollments'
              refreshDataFunc={getAgentEnrollments}
              columnBuilder={_buildDynamicColumns}
            />
            <div className='card-footer border-top-0'></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AgentEnrollments;